/* ButtonComponent.css */

.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
  
  .transition-transform-active {
    animation: myAnim 2s ease 0s 1 normal forwards;
  }

  .transition-transform-active-button {
    animation: myAnim 2s ease 0s 1 normal forwards;
  }

  .hidden {
    display: none;
  }
  
  @keyframes myAnim {
    0% {
      opacity: 0;
      transform: scale(1);
    }
  
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
