.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-logo {
    background-image: url('./assets/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    animation: loadingAnimation 1.5s ease-in-out infinite;
  }
  
  @keyframes loadingAnimation {
    0%, 100% {
      transform: scale(1); /* Initial and final scale is 1 */
    }
    50% {
      transform: scale(1.2); /* Scaled up during the middle of the animation */
    }
  }
  