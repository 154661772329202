@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@600&display=swap');

@font-face {
  font-family: 'Futura';
  src: url('./fonts/Futura.ttf') format('truetype');
}

@font-face {
  font-family: 'Kollektif';
  src: url('./fonts/Kollektif.ttf') format('truetype');
}

@font-face {
  font-family: 'Kollektif-Bold';
  src: url('./fonts/Kollektif-Bold.ttf') format('truetype');
}

p, span {
  font-family: 'Kollektif', sans-serif;
}

b {
  font-family: 'Futura', sans-serif;
}

.font {
  font-family: 'Futura', sans-serif;
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

h1 {
  font-family: 'League Spartan', sans-serif;
}

@media screen and (max-width: 1440px) {
  .flex {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .flex {
    width: 100%;
  }

  h1 {
    font-size: 15px;
  }

  .color {
    background-color: #B91D1B;
    color: white;
  }

  .color:hover {
    background-color: #B91D1B;
    color: white;
  }
}

.sticky-container {
  position: sticky;
  top: 0;
  margin-top: 20px; /* Adjust this margin based on your design */
}
