.animated-border {
    position: relative;
    overflow: hidden;
  }
  
  .animated-border::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid transparent;
    border-radius: 12px;
    animation: animateBorder 1.5s infinite linear;
  }
  
  @keyframes animateBorder {
    0% {
      border-color: #00FF00; /* Start color of the border */
    }
    50% {
      border-color: #FFD700; /* Middle color of the border */
    }
    100% {
      border-color: #00FF00; /* End color of the border */
    }
  }
